import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {},
  actions: {
    fetchOrder(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`/orders/${payload.orderKey}/complete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
